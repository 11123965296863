export default {
    oidc: {
        clientId: process.env.VUE_APP_OKTA_ID,
        issuer: process.env.VUE_APP_OKTA_DOMAIN,
        redirectUri: process.env.VUE_APP_OKTA_REDIRECT + "/authorization-code/callback",

        scopes: ["openid", "profile", "email"],
        // Use authorization_code flow
        responseType: 'code',
        pkce: true,
        responseMode: "query"
    }
};
