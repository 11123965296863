import { updateObject } from "@/store/utility";

const getDefaultState = () => {
    return {
        returningFromDetails: false,
        lastId: null,
        searchObjDropdown: {
            timer: null,
            dropdown: true,
            menu: false,
            minLength: 3
        },
        searchValue: "",
        filtersInteractive: [],
        dropdownStores: [],
        filtersDropdown: {
            modal: false,
            items: [],
            filters: {}
        }
    };
};

const state = getDefaultState();

const getters = {
    getReturningFromDetails: state => state.returningFromDetails,
    getSearchValue: state => state.searchValue,
    getFiltersInteractive: state => state.filtersInteractive,
    getFiltersDropdown: state => state.filtersDropdown,
    getDropdownStores: state => state.dropdownStores,
    getLastId: state => state.lastId,
    getSearchDropdownObj: state => state.searchObjDropdown
};

const mutations = {
    reset(state) {
        Object.assign(state, getDefaultState());
    },
    setReturningFromDetails(state, payload) {
        state.returningFromDetails = payload;
    },
    setLastId(state, payload) {
        state.lastId = payload;
    },
    setSearchObjDropdown(state, { property, value }) {
        state.searchObjDropdown[property] = value;
    },
    setSearchValue(state, payload) {
        state.searchValue = payload;
    },
    setFiltersInteractive(state, payload) {
        state.filtersInteractive = payload;
    },
    setDropdownStores(state, payload) {
        state.dropdownStores = payload;
    },
    filterInteractiveToggleParentAndChildren(state, parentFilter) {
        const newState = parentFilter.value !== parentFilter.filters.length;
        const parentFilterState = state.filtersInteractive.find(f => f.id === parentFilter.id);
        parentFilterState.filters.forEach(f => {
            f.value = newState;
        });
        parentFilterState.value = newState ? parentFilterState.filters.length : 0;
    },
    toggleFilterInteractive(state, { parentFilter, filter, value }) {
        const parentFilterState = state.filtersInteractive.find(f => f.id === parentFilter.id);
        if (value) {
            parentFilterState.value++;
        } else {
            parentFilterState.value--;
        }

        if (!parentFilter.highlight && filter) {
            const filterState = parentFilterState.filters.find(f => f.id === filter.id);
            filterState.value = value;
        }
    },
    toggleFilterInteractiveOpen(state, filter) {
        const filterState = state.filtersInteractive.find(f => f.id === filter.id);
        filterState.open = !filterState.open;
    },
    pushToFiltersInteractive(state, payload) {
        state.filtersInteractive.push(payload);
    },
    resetFiltersInteractive(state) {
        for (let i = 0; i < state.filtersInteractive.length; i++) {
            let filter = state.filtersInteractive[i];
            filter.value = 0;
            if (filter.filters) {
                for (let j = 0; j < filter.filters.length; j++) {
                    filter.filters[j].value = false;
                }
            }
        }
    },
    removeFilterInteractive(state, { parentFilterId, filterId }) {
        let parentFilterIdx = state.filtersInteractive.findIndex(filter => filter.id === parentFilterId);
        if (parentFilterIdx !== -1) {
            let filterIdx = state.filtersInteractive[parentFilterIdx].filters.findIndex(f => f.id === filterId);
            if (filterIdx !== -1) {
                const toggleVal = {
                    parentFilter: state.filtersInteractive[parentFilterIdx],
                    filter: state.filtersInteractive[parentFilterIdx].filters[filterIdx],
                    value: false
                };
                this.commit("filter/toggleFilterInteractive", toggleVal);
            } else if (
                state.filtersInteractive[parentFilterIdx].highlight ||
                state.filtersInteractive[parentFilterIdx].inspirational
            ) {
                const toggleVal = {
                    parentFilter: state.filtersInteractive[parentFilterIdx],
                    filter: null,
                    value: false
                };
                this.commit("filter/toggleFilterInteractive", toggleVal);
            }
        }
    },

    setFiltersDropdown(state, { property, value }) {
        updateObject(state.filtersDropdown, property, value);
    }
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
