import { OktaAuth } from '@okta/okta-auth-js'
import oktaConfig from './okta.config'

export const oktaAuth = new OktaAuth({
  issuer: oktaConfig.oidc.issuer,
  clientId: oktaConfig.oidc.clientId,
  redirectUri: oktaConfig.oidc.redirectUri,
  responseType: 'code',
  scopes: oktaConfig.oidc.scopes,
  pkce: true,
  responseMode: "query"
})
