import { createStore } from "vuex"
import places from "./module/places";
import city from "./module/city";
import user from "./module/user";
import tour from "./module/tour";
import feedback from "./module/feedback";
import interest from "./module/interest";
import tools from "./module/tools";
import tourCreation from "./module/tourCreation";
import filter from "./module/filter";
import VuexPersist from "vuex-persist";
import apiFactory from "../api/api";
const STORAGE_KEY = "nike-pwa-store";


// https://alligator.io/vuejs/vuex-persist-state/
const vuexLocalStorage = new VuexPersist({
    key: STORAGE_KEY,
    storage: window.localStorage,
    reducer: state => ({
        loaded: state.loaded,
        user: state.user,
        city: state.city,
        interest: state.interest,
        places: state.places,
        tour: state.tour,
        feedback: state.feedback,
		tourCreation: state.tourCreation,
		filter: state.filter
    })
});

const debug = process.env.NODE_ENV !== "production";

const getDefaultState = () => {
    return {
        loaded: false
    };
};

export const store = createStore({
    modules: {
        places,
        user,
        city,
        tour,
        feedback,
        interest,
        tools,
		tourCreation,
		filter
    },
    state: getDefaultState(),
    actions: {
        async load({ commit, dispatch }) {
            commit("tools/showAppLoader", "root-store", { root: true });
            await dispatch("user/load");

            commit("setLoaded", true);
        },
        async reset({ commit }) {
            commit("user/reset");
            commit("city/reset");
            commit("feedback/reset");
            commit("interest/reset");
            commit("places/reset");
            commit("tour/reset");
            commit("tools/reset");
			commit("tourCreation/reset");
			commit("filter/reset");
            commit("reset");
        }
    },
    mutations: {
        setLoaded: (state, val) => (state.loaded = val),
        initApi: state => {
            window.api = apiFactory(state.user.accessToken);
        },
        reset: state => {
            Object.assign(state, getDefaultState());
        }
    },

    plugins: [vuexLocalStorage.plugin],
    strict: debug
});
