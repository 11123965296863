const getDefaultState = () => {
    return {
        // all the feedbacks
        feedbacks: [],
        // feedbacks ids ordered by stores id
        feedbacksStores: {},
        // feedbacks ids ordered by tours id
        feedbacksTour: {},
        // feedback arrays ordered by their tour ID
        feedbacksByTour: [],
        // feedbacks count
        feedbackTypes: [],
        // stores feedbacks count
        storeFeedbackNumbers: []
    };
};
const state = getDefaultState();

const getters = {
    getFeedbackById: state => id => {
        return state.feedbacks ? state.feedbacks.find(feedback => feedback.id === id) : null;
    },
    getFeedbacksByStore: (state, getters) => storeId => {
        let feedbacksIds = state.feedbacksStores[storeId];
        let result = [];
        if (feedbacksIds) {
            for (var i = 0; i < feedbacksIds.length; i++) {
                let feedback = getters.getFeedbackById(feedbacksIds[i]);
                if (feedback) {
                    result.push(feedback);
                }
            }
        }

        return result;
    },
    getFeedbacksByTour: state => tourID => {
        const element = state.feedbacksByTour.find(tour => tour.tourID === tourID);
        return element ? element.feedbacks : undefined;
    },
    getFeedbacksByAuthor: (state, getters) => (authorId, storeId) => {
        const feedbacks = getters.getFeedbacksByStore(storeId);
        let relevantFeedbacks = [];

        for (let i = 0; i < feedbacks.length; i++) {
            if (feedbacks[i].author.id === Number(authorId)) {
                relevantFeedbacks.push(feedbacks[i]);
            }
        }

        return relevantFeedbacks;
    },
    getFeedbacksByTourAndAuthor: (state, getters) => (authorId, tourID) => {
        const feedbacks = getters.getFeedbacksByTour(tourID);
        let relevantFeedbacks = [];

        if (feedbacks) {
            for (let i = 0; i < feedbacks.length; i++) {
                if (feedbacks[i].author.id === Number(authorId)) {
                    relevantFeedbacks.push(feedbacks[i]);
                }
            }
        }

        return relevantFeedbacks;
    }
};

const mutations = {
    setFeedbacks(state, feedbacks) {
        state.feedbacks = feedbacks;
    },
    setFeedbacksByStores(state, feedbacks) {
        let feedbacksStores = {};
        for (var i = 0; i < feedbacks.length; i++) {
            if (!feedbacks[i].store) continue;
            let storeId = feedbacks[i].store.id;
            if (!feedbacksStores[storeId]) {
                feedbacksStores[storeId] = [];
            }
            feedbacksStores[storeId].push(feedbacks[i].id);
        }
        state.feedbacksStores = feedbacksStores;
    },
    setStoreFeedbackNumber(state, data) {
        state.storeFeedbackNumbers.push({ storeId: data.storeId, tourId: data.tourId, normal: data.normal });
    },
    setFeedbackTypes(state, data) {
        state.feedbackTypes.push({ tourNumber: data.tourNumber, normal: data.normal });
    },
    addFeedback(state, feedback) {
        // todo: structure of state.feedbacksStores is not the same as feedback object
        // if (feedback.store) {
        //     state.feedbacksStores.push(feedback);
        // }
        state.feedbacks.push(feedback);
    },
    updateStoreFeedbackTypesCount(state, { storeId, tourId, action }) {
        const tourIndex = state.storeFeedbackNumbers.findIndex(item => {
            return item.storeId === parseInt(storeId) && item.tourId === parseInt(tourId);
        });
        let countNormal = state.storeFeedbackNumbers[tourIndex].normal;
        if (action === "deleted") {
            countNormal -= 1;
        } else if (action === "added") {
            countNormal += 1;
        } else {
            countNormal += 1;
        }

        state.storeFeedbackNumbers[tourIndex].normal = countNormal;
    },
    updateFeedbackTypesCount(state, { tourId, action }) {
        const tourIndex = state.feedbackTypes.findIndex(item => item.tourNumber === parseInt(tourId));
        if (tourIndex !== -1) {
            let countNormal = state.feedbackTypes[tourIndex].normal;

            if (action === "deleted") {
                countNormal -= 1;
            } else if (action === "added") {
                countNormal += 1;
            } else {
                countNormal += 1;
            }

            state.feedbackTypes[tourIndex].normal = countNormal;
        } else {
            const newFeedbackTypeEntry = {
                tourNumber: parseInt(tourId),
                normal: 1
            };
            state.feedbackTypes.push(newFeedbackTypeEntry);
        }
    },
    updateFeedbackEntry(state, updatedFeedback) {
        const oldFeedbackIndex = state.feedbacks.findIndex(feedback => feedback.id === updatedFeedback.id);

        state.feedbacks[oldFeedbackIndex] = updatedFeedback;
    },
    updateFeedbackByTourEntry(state, updatedFeedback) {
        let oldFeedbackIndex = null;
        for (let i = 0; i < state.feedbacksByTour.length; i++) {
            oldFeedbackIndex = state.feedbacksByTour[i].feedbacks.findIndex(
                feedback => feedback.id === updatedFeedback.id
            );
            if (oldFeedbackIndex > -1) {
                state.feedbacksByTour[i].feedbacks[oldFeedbackIndex] = updatedFeedback;
            }
        }
    },
    addFeedbackToStore(state, data) {
        let feedbackId = data.resId;
        let storeId = data.storeId;

        if (!state.feedbacksStores[storeId]) {
            state.feedbacksStores[storeId] = [feedbackId];
        } else {
            state.feedbacksStores[storeId].push(feedbackId);
        }
    },
    removeFeedback(state, feedbackID) {
        const feedbackIndex = state.feedbacks.findIndex(item => item.id === feedbackID);
        state.feedbacks.splice(feedbackIndex, 1);
    },
    removeFeedbackByTour(state, feedbackID) {
        let feedbackIndex = null;
        for (let i = 0; i < state.feedbacksByTour.length; i++) {
            feedbackIndex = state.feedbacksByTour[i].feedbacks.findIndex(item => item.id === feedbackID);
            if (feedbackIndex > -1) {
                state.feedbacksByTour[i].feedbacks.splice(feedbackIndex, 1);
            }
        }
    },
    setFeedbacksByTour(state, { tourID, feedbacks }) {
        state.feedbacksByTour.push({ tourID: tourID, feedbacks: feedbacks });
    },
    setFeedbacksByTourAndAuthor(state, { authorId, tourId, feedbacks }) {
        state.feedbacksByTour.push({ authorId: authorId, tourID: tourId, feedbacks: feedbacks });
    },
    addFeedbackToTour(state, params) {
        const tourIndex = state.feedbacksByTour.findIndex(tour => tour.tourID === params.tourId);
        state.feedbacksByTour[tourIndex].feedbacks.push(params.feedback);
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    async loadFeedbacksByAuthorAndCity({ commit, state, rootState }, { authorId, cityId } = {}) {
        authorId = authorId || rootState.user.userInfo.id;
        cityId = cityId || rootState.city.currentCity.id;

        if (state.feedbacks.length) {
            return state.feedbacks.filter(fb => fb.author.id === authorId);
        } else {
            let feedbacks = await api.request("feedback", {
                "author.id": authorId,
                "tour.city.id": cityId //FIXME: allows filters tour.city.id ===cityId || store.tradezone.city.id === cityId
            });

            // TODO: IN API => allow filtering by city even if tour is null
            // POSSIBLE FRONT-END WORKAROUND: (NOT WORKING BECAUSE fb.tour.city.id and fb.store.tradezone.city.id are not exposed by api)
            // feedbacks = feedbacks.filter(fb => fb.tour && fb.tour.city && fb.tour.city.id === cityId ||
            //                              fb.store && fb.store.tradezone.city.id === cityId);

            commit("setFeedbacks", feedbacks);
            commit("setFeedbacksByStores", feedbacks);
            return feedbacks;
        }
    },
    async loadFeedbacksByTour({ commit, getters }, tourID) {
        let feedbacks = getters.getFeedbacksByTour(tourID);
        if (!feedbacks) {
            feedbacks = await api.request("feedback", {
                "tour.id": tourID
            });

            commit("setFeedbacksByTour", { tourID, feedbacks });
        }

        return feedbacks;
    },
    loadFeedBackCount({ commit, rootState }, { tours } = {}) {
        tours = tours || rootState.tour.availableTours;

        if (tours.length) {
            tours.forEach(tour => {
                let tourNormalFbCount = 0;
                let tourNormalFbWithoutStoreCount = 0;

                if (tour.feedbacks) {
                    Object.keys(tour.feedbacks).forEach(key => {
                        const tourFbCounts = tour.feedbacks[key];
                        const storeId = parseInt(key);
                        if (storeId === 0) {
                            // Feedback without store
                            tourNormalFbWithoutStoreCount += parseInt(tourFbCounts.normal);
                        } else {
                            tourNormalFbCount += parseInt(tourFbCounts.normal);
                            commit("setStoreFeedbackNumber", {
                                storeId: parseInt(storeId),
                                tourId: parseInt(tour.id),
                                normal: parseInt(tourFbCounts.normal)
                            });
                        }
                    });

                    commit("setFeedbackTypes", {
                        tourNumber: tour.id,
                        normal: tourNormalFbCount + tourNormalFbWithoutStoreCount
                    });
                }
            });
        }
    },
    async loadFeedbacksByTourAndAuthor({ commit, getters, rootState }, params) {
        let feedbacks = getters.getFeedbacksByTourAndAuthor(params.authorId, params.tourId);
        let authorId = params.authorId || rootState.user.userInfo.id;
        let tourId = params.tourId;

        if (!feedbacks.length > 0) {
            feedbacks = await api.request("feedback", {
                "author.id": authorId,
                "tour.id": tourId
            });

            if (feedbacks.length > 0) {
                commit("setFeedbacksByTourAndAuthor", { authorId, tourId, feedbacks });
            }
        }

        return feedbacks;
    },
    loadFeedbacksByStore({ commit, getters }, storeID) {
        return new Promise((resolve, reject) => {
            let feedbacks = getters.getFeedbacksByStore();
            if (feedbacks.length) {
                resolve(feedbacks);
            } else {
                api.request(
                    "feedback",
                    {
                        "store.id": storeID
                    },
                    feedbacks => {
                        commit("setFeedbacks", feedbacks);
                        commit("setFeedbacksByStores", feedbacks);
                        resolve(feedbacks);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    },
    createFeedback({ commit, dispatch, state }, { description, authorId, author, storeId, tourId, images }) {
        return new Promise((resolve, reject) => {
            api.post(
                "feedback",
                {
                    description: description,
                    author: "/api/users/" + authorId,
                    store: storeId ? "/api/stores/" + storeId : null,
                    tour: tourId ? "/api/tours/" + tourId : null,
                    images: images
                },
                async res => {
                    let updated = res;

                    updated.author.fullname = author;

                    commit("addFeedback", updated);
                    if (res.store) {
                        commit("addFeedbackToStore", { resId: res.id, storeId: storeId });
                    }

                    if (res.tour) {
                        if (
                            !state.feedbacksByTour.length ||
                            !state.feedbacksByTour.find(tour => tour.tourID === tourId)
                        ) {
                            await dispatch("loadFeedbacksByTour", tourId);
                        } else {
                            commit("addFeedbackToTour", { feedback: res, tourId: tourId });
                        }
                    }

                    commit("tour/updateAllUserTours", { storeId, tourId }, { root: true });
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    // eslint-disable-next-line
    createImage({}, file) {
        return new Promise((resolve, reject) => {
            api.post(
                "images",
                file,
                res => {
                    resolve(res);
                },
                error => {
                    reject(error);
                },
                "multipart/form-data"
            );
        });
    },
    updateFeedback({ commit }, { description, authorId, author, storeId, tourId, images, feedbackId }) {
        return new Promise((resolve, reject) => {
            api.put(
                "feedback/" + feedbackId,
                {
                    description: description,
                    author: "/api/users/" + authorId,
                    store: storeId ? "/api/stores/" + storeId : null,
                    tour: tourId ? "/api/tours/" + tourId : null,
                    images: images
                },
                res => {
                    let updated = res.data;

                    updated.author.fullname = author;
                    commit("updateFeedbackByTourEntry", updated);
                    commit("updateFeedbackEntry", updated);
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    updateFeedbackTypes({ commit }, { tourId, storeId, action = null } = {}) {
        commit("updateFeedbackTypesCount", { tourId, action });
        commit("updateStoreFeedbackTypesCount", { storeId, tourId, action });
    },
    deleteFeedback({ commit }, feedbackID) {
        return new Promise((resolve, reject) => {
            api.delete(
                "feedback",
                {
                    id: feedbackID
                },
                res => {
                    commit("removeFeedbackByTour", feedbackID);
                    commit("removeFeedback", feedbackID);
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
