import { store } from "@/store";

export const mapSetter = (moduleName, stateNameArray) =>
    stateNameArray.reduce((acc, stateName) => {
        acc[stateName] = {
            get: () => store.state[moduleName][stateName]
        };

        // check if setter exists
        const setterName = getSetterName(moduleName, stateName);
        if (setterName) {
            acc[stateName].set = payload => {
                store.commit(setterName, payload);
            };
        }

        return acc;
    }, {});

const getSetterName = (moduleName, stateName) => {
    const stateSetter = capitalizeFirstLetter(stateName);
    const fullSetterName = `${moduleName}/set${stateSetter}`;
    if (store._mutations[fullSetterName]) {
        return fullSetterName;
    }
};

const capitalizeFirstLetter = stateName => stateName.charAt(0).toUpperCase() + stateName.slice(1);

export const updateObject = (data, path, value) => {
    var pathArray = path.split(".");
    var pointer = data; // points to the current nested object
    for (var i = 0, len = pathArray.length; i < len; i++) {
        let pathSegment = pathArray[i];
        if (Object.prototype.hasOwnProperty.call(pointer, pathSegment)) {
            if (i === len - 1) {
                // terminating condition
                pointer[pathSegment] = value;
            } else {
                pointer = pointer[pathSegment];
            }
        } else {
            // Property not found
        }
    }
};
