import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";
import { i18n } from "./i18n";
import Vue3TouchEvents from "vue3-touch-events";
import VueTransitions, { TransitionExpand, TransitionFade } from "@morev/vue-transitions";
import OktaVue from "@okta/okta-vue"
import { oktaAuth } from "./auth"
import "@morev/vue-transitions/styles";

const app = createApp(App);
app.use(router);
app.use(OktaVue, { oktaAuth });
app.use(i18n);
app.use(store);
app.use(VueTransitions, {
  // Plugin options (optional)
});
app.use(Vue3TouchEvents, {
  swipeTolerance: 100,
});
app.component("TransitionExpand", TransitionExpand);
app.component("TransitionFade", TransitionFade);
app.mount('#app');
