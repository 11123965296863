import moment from "moment-fquarter";
import * as _ from "underscore";

/**
 * @param {Object} place from APi
 * @param {Number} id
 * @param {String} type
 * @return {Object} place reformat for place page
 */
export function Place(place, id, type) {
    const updateData = place?.keydata ? place.keydata?.updatedAt : place?.updatedAt;
    const dateUpdate = moment.utc(updateData).local().format("DD/MM/YYYY");

    const orderPlace = {
        id: id,
        details: {
            description: place.description,
            map: {
                latitude: place.latitude,
                link: "https://www.google.com/maps/search/?api=1&query=" + place.latitude + "," + place.longitude,
                longitude: place.longitude
            },
            name: place.name,
            tags: _.size(place.tags) === 0 ? null : place.tags,
            type: type,
            website: place.website,
            isMustWinDoor: place.isMustWinDoor,
            openHours: place.openHours,
        },
        keyData: {
            data: place.keydata,
            update: dateUpdate,
            year: moment
                .utc(place?.keydata?.updatedAt)
                .local()
                .format("YY"),
            lastYear: moment
                .utc(place?.keydata?.updatedAt)
                .fquarter(6)
                .year,
        },
        revenue: {
            update: dateUpdate
        },
        stores: {
            list: place.stores,
            count: place.totalItems
        },
        campaigns: place.campaigns ? place.campaigns : null,
        inspirationalPlaces: place.inspirationalPlaces
            ? place.inspirationalPlaces
            : place.inspirational_places && place.inspirational_places.length > 0
            ? place.inspirational_places
            : null,
        fieldOfPlay: place.fieldsOfPlay
    };
    switch (type) {
        case "stores":
            orderPlace.details.address = place.fullAddress;
            orderPlace.details.partner = place.partner === null ? null : place.partner;
            orderPlace.revenue.split = _.size(place.split) === 0 ? null : place.split;
            orderPlace.details.tradezone = place.tradezone === null ? null : place.tradezone;
            orderPlace.details.images = _.size(place.images) === 0 ? [""] : place.images;
            orderPlace.details.image = [place.images[0] ? place.images[0].path : ""];
            break;
        case "tradezones":
            orderPlace.details.zone = place.zone;
            orderPlace.details.images = {};
            orderPlace.details.images.path = orderPlace.details.image = [place.image];
            break;
        case "partners":
            orderPlace.details.channel = place.channel;
            orderPlace.details.images = {};
            orderPlace.details.images.path = orderPlace.details.image = [place.image];
            break;
        case "events":
            orderPlace.details.website = place.urlWeb
            orderPlace.details.startDate = moment.parseZone(place.startDate).format("dddd DD/MM");
            orderPlace.details.address = place.address;
            orderPlace.details.tradezone = place.tradezone === null ? null : place.tradezone;
            orderPlace.details.zone = place.zone;
            orderPlace.details.images = [{ path: "" }];
			if (place.images && Object.keys(place.images).length > 0) {
				const pathArray = Object.values(place.images).map(val => ({ path: val.slider }));
				orderPlace.details.images = pathArray;
			}
            break;
        case "inspirational-places":
            orderPlace.details.address = place.address;
            orderPlace.details.images = [{ path: "" }];
			if (place.images && Object.keys(place.images).length > 0) {
				const pathArray = Object.values(place.images).map(val => ({ path: val.slider }));
				orderPlace.details.images = pathArray;
			}
            break;
    }

    for (var data in orderPlace.keyData.data) {
        if (data.match(/^[@].*$/) || data.match("createdAt") || data.match("updatedAt")) {
            delete orderPlace.keyData.data[data];
        }
    }

    return orderPlace;
}

/**
 * @param campaigns
 * @return {*}
 */
export function Campaigns(campaigns) {
    const orderCampaigns = [];
    let count = 0;
    for (let campaign of campaigns) {
        orderCampaigns[count] = {
            id: campaign.id,
            image: campaign.images[0] ? campaign.images[0].background : null,
            name: campaign.shortTitle,
            position: count + 1,
            contents: [
                {
                    type: "content",
                    title: campaign.name,
                    description: campaign.description ? campaign.description : null
                }
            ]
        };

        const keydata = campaign.keyData.filter(element => element.data !== null || element.title !== null);
        if (keydata.length) {
            orderCampaigns[count].contents.push({
                type: "keydata",
                description: keydata
            });
        }

        if (campaign.video) {
            orderCampaigns[count].contents.push({
                type: "video",
                description: campaign.video
            });
        }

        campaign.images.forEach(image => {
            orderCampaigns[count].contents.push({
                type: "image",
                image: image.slider
            });
        });
        count++;
    }

    return orderCampaigns;
}

/**
 * @param places
 * @return {*}
 */
export function InspirationalPlace(places) {
    const orderPlace = [];
    let count = 0;
    for (let place of places) {
        orderPlace[count] = {
            id: place.id,
            name: place.name,
            position: count + 1,
            image: place.images[0] ? place.images[0].mosaic : null,
            contents: [
                {
                    type: "contentWithCTA",
                    title: place.name,
                    description: place.description,
                    link: "https://www.google.com/maps/search/?api=1&query=" + place.latitude + "," + place.longitude
                },
                {
                    type: "store",
                    title: place.address,
                    description: place.openHours
                }
            ]
        };

        place.images.forEach(image => {
            orderPlace[count].contents.push({
                type: "image",
                image: image.mosaic
            });
        });

        count++;
    }

    return orderPlace;
}
